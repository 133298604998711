import { priceNumberToLabel } from '../../utils';
import './Cart.css';
import Quantity from '../quantity/Quantity';
import Checkout from '../checkout/Checkout';
import classNames from 'classnames';

function Cart({
    cart,
    addProductToCart,
    removeProductToCart,
    setIsVisibleMobileCart,
    fullScreen,
}) {
    const initialValue = 0;
    const total = cart.reduce(
        (accumulator, product) => {
            const price = product.price
                ? product.quantity * product.price
                : 0;
            return accumulator + price;
        },
        initialValue,
    );

    return (
        <div className={classNames(
            "Cart",
            {"Cart--fullScreen": fullScreen}
        )}>
            <div className="Cart-order Cart-col">
                <h3 className="Aside-title">
                    Bon de commande
                </h3>
                <ul className="Cart-list">
                    {cart.map(product =>
                        <li key={product.id} className="Cart-item">
                            <img
                                className="Cart-item-img"
                                src={`./images/products/${product.image}`}
                                alt={product.label}
                            />
                            <span className="Cart-item-label">
                                {product.label}
                                {product.sublabel && <>
                                    <br />{product.sublabel}
                                </>}
                            </span>
                            <Quantity
                                product={product}
                                addProductToCart={addProductToCart}
                                removeProductToCart={removeProductToCart}
                            />
                            {product.price &&
                                <p className="Cart-item-price">
                                    {priceNumberToLabel(product.price)}
                                </p>
                            }
                            {product.exchange &&
                                <p className="Cart-item-price Cart-item-price--exchange">
                                    Échange
                                </p>
                            }
                        </li>
                    )}
                </ul>

                {cart.length === 0
                    ? (
                        <p className="Cart-empty">
                            Aucun produit dans ma commande
                            <br />
                            <a
                                href="#vitrine"
                                title="Voir la liste des produits"
                                className="Cart-empty-link"
                                onClick={() => setIsVisibleMobileCart(false)}
                            >
                                Voir la liste des produits
                            </a>
                        </p>
                    ) : (<>
                        <div className="Cart-bottom">
                            <p className="Cart-total">
                                Total :&nbsp;
                                <span className="Cart-total-value">{priceNumberToLabel(total)}</span>
                            </p>
                        </div>
                        
                    </>)
                }
            </div>

            <div className="Cart-checkout Cart-col">
                {cart.length > 0 && <>
                    <h3 className="Cart-checkout-title Aside-title">
                        Mode de retrait
                    </h3>
                    <Checkout cart={cart} />
                </>}
            </div>

            <div className="Cart-contacts Cart-col">
                <h3 className="Aside-title">
                    Une question ?
                </h3>

                <ul className="Cart-contacts-list">
                    <li className="Cart-contacts-item">
                        shop@trucland.org
                    </li>
                    <li className="Cart-contacts-item">
                        <a
                            href="http://facebook.com/trucland.asso"
                            title="Visiter la page facebook de Truc'land"
                            target="_blank"
                            rel="noreferrer"
                        >
                            facebook.com/trucland.asso
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default Cart;
