import logo from '../../logo.svg';
import iconShop from '../../icon-shop.svg';
import iconCart from '../../icon-basket.svg';
import './Header.css';
import classNames from 'classnames';

function Header({
    isVisibleMobileCart,
    setIsVisibleMobileCart,
}) {
    return (
        <header className="Header">
            <div className="Header-main">
                <a
                    className="Header-logo"
                    href="/"
                    title="Truc'shop, mini-boutique associative de l'écolieu Truc'land"
                >
                    <img
                        src={logo}
                        className="Header-logo-img"
                        alt="Truc'shop"
                    />
                </a>

                <ul className="Header-nav">
                    <li className="Header-nav-item">
                        <a
                            className="Header-shop"
                            href="#vitrine"
                            title="Voir les produits"
                            onClick={() => setIsVisibleMobileCart(false)}
                        >
                            <img
                                src={iconShop}
                                className={classNames(
                                    "Header-nav-img",
                                    {"Header-nav-img--current": !isVisibleMobileCart}
                                )}
                                alt="Voir ma commande"
                            />
                        </a>
                    </li>
                    <li className="Header-nav-item">
                        <a
                            className="Header-cart"
                            href="#ma-commande"
                            title="Voir ma commande"
                            onClick={() => setIsVisibleMobileCart(true)}
                        >
                            <img
                                src={iconCart}
                                className={classNames(
                                    "Header-nav-img",
                                    {"Header-nav-img--current": isVisibleMobileCart}
                                )}
                                alt="Voir ma commande"
                            />
                        </a>
                    </li>
                </ul>
            </div>
            <div className="Header-aside">
                <h1 className="Header-title">
                    Mini-boutique associative de l'écolieu Truc'Land
                </h1>
            </div>
        </header>
    );
}

export default Header;