import { useState } from 'react';
import './Main.css';
import Aside from '../aside/Aside';
import Catalog from '../../components/catalog/Catalog';
import Cart from '../../components/cart/Cart';

function Main({
    isVisibleMobileCart,
    setIsVisibleMobileCart,
}) {
    const initialCart = JSON.parse(localStorage.getItem("cart")) || [];
    const [cart, setCart] = useState(initialCart);

    const saveCart = (newCart) => {
        localStorage.setItem("cart", JSON.stringify(newCart));
    }

    const addProductToCart = (newProduct) => {
        let newCart = [...cart];
        const isInCart = cart.findIndex((product) => product.id === newProduct.id);
        if(isInCart !== -1) {
            newCart[isInCart].quantity++; 
        } else {
            newCart = [
                ...newCart,
                {
                    ...newProduct,
                    quantity: 1,
                }
            ];
        }
        setCart(newCart);
        saveCart(newCart);
    }

    const removeProductToCart = (newProduct) => {
        let newCart = [...cart];
        const productInCartIndex = cart.findIndex(
            (product) => product.id === newProduct.id
        );
        if(productInCartIndex !== -1) {
            if(newCart[productInCartIndex].quantity === 1) {
                newCart = [
                    ...cart.filter(product => product.id !== newProduct.id)
                ];
            } else {
                newCart[productInCartIndex].quantity--;
            }
        }
        setCart(newCart);
        saveCart(newCart);
    }

    return (
        <section className="Main">
            {isVisibleMobileCart
                ? (
                    <Cart
                        cart={cart}
                        addProductToCart={addProductToCart}
                        removeProductToCart={removeProductToCart}
                        setIsVisibleMobileCart={setIsVisibleMobileCart}
                        fullScreen
                    />
                ) : (<>
                    
                    <Catalog
                        cart={cart}
                        addProductToCart={addProductToCart}
                        removeProductToCart={removeProductToCart}
                    />
                    <Aside
                        cart={cart}
                        addProductToCart={addProductToCart}
                        removeProductToCart={removeProductToCart}
                    />
                </>)
            }
        </section>
    );
}

export default Main;
