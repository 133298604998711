import './Catalog.css';
import data from "../../api/catalog.json";
import { useState } from 'react';
import classNames from 'classnames';
import { priceNumberToLabel } from '../../utils';
import Quantity from '../../components/quantity/Quantity';

function Catalog({
    cart,
    addProductToCart,
    removeProductToCart,
}) {
    const { categories, products } = data;
    const initialCategory = categories.find(
        category => `#${category.slug}` === window.location.hash
    );
    const [currentCategory, setCurrentCategory] = useState(initialCategory?.id || null);
    
    const setAndSaveCurrentCategory = (category) => {
        setCurrentCategory(category.id);
        window.location.hash = `#${category.slug}`;
    }

    const getCategoryById = (id) =>
        categories.find((item) => item.id === id);

    const filteredProducts = currentCategory
        ? products.filter(product => product.categoryId === currentCategory)
        : products;

    const orderedProducts = filteredProducts.sort((a, b) =>
        a.categoryId - b.categoryId
    );

    const renderEmptyBoxes = (length) => {
        let boxes = [];
        for(let i = 0; i < 6 - length; i++) {
            boxes = [
                ...boxes,
                <li className="Catalog-product Catalog-product--empty" />
            ];
        }
        return boxes;
    }

    const isInCart = (product) =>
        cart.find(item => item.id === product.id);

    return (
        <section className="Catalog">
            <section className="Catalog-content">
                <div className="Catalog-categories">
                    <select className="Catalog-categories-select">
                        <option className="Catalog-categories-option">
                            Tous les rayons
                        </option>
                    </select>
                    <ul className="Catalog-categories-list">
                        {categories.map(category =>
                            <li key={category.id} className="Catalog-categories-item">
                                <button
                                    onClick={() => setAndSaveCurrentCategory(category)}
                                    className={classNames(
                                        "Catalog-categories-button",
                                        { "Catalog-categories-button--current": category.id === currentCategory }
                                    )}
                                >
                                    {category.label}
                                </button>
                            </li>
                        )}
                    </ul>
                    <p className="Catalog-categories-description">
                        {getCategoryById(currentCategory)?.description}
                    </p>
                </div>

                {orderedProducts.length > 0
                    ? (
                        <div className="Catalog-products">
                            <ul className="Catalog-products-list">
                                {orderedProducts.map((product) => 
                                    <li
                                        key={product.id}
                                        className="Catalog-product"
                                    >
                                        <a
                                            className="Catalog-product-thumb"
                                            href={`#${product.id}`}
                                            title={`Détails du produit ${product.label}`}
                                        >
                                            <img
                                                className={classNames(
                                                    "Catalog-product-img",
                                                    {"Catalog-product-img--outOfStock": product.stock === 0}
                                                )}
                                                src={`./images/products/${product.image}`}
                                                alt={product.label}
                                            />
                                            <p className="Catalog-product-category">
                                                {getCategoryById(product.categoryId)?.label}
                                            </p>
                                        </a>
                                        <div className="Catalog-product-content">
                                            <a
                                                className="Catalog-product-details"
                                                href={`#${product.id}`}
                                                title={`Détails du produit ${product.label}`}
                                            >
                                                <h4 className="Catalog-product-label">
                                                    {product.label}
                                                    { product.sublabel && <>
                                                        <br />
                                                        <span className="Catalog-product-sublabel">
                                                            {product.sublabel}
                                                        </span>
                                                    </>}
                                                </h4>
                                                { product.date &&
                                                    <p className="Catalog-product-subtitle">
                                                        {product.date}
                                                    </p>
                                                }
                                                { product.ingredients &&
                                                    <p
                                                        dangerouslySetInnerHTML={{__html: product.ingredients}}
                                                        className="Catalog-product-description"
                                                    />
                                                }
                                            </a>
                                            <div className="Catalog-product-bottom">
                                                {product.price &&
                                                    <p className="Catalog-product-price">
                                                        {priceNumberToLabel(product.price)}
                                                    </p>
                                                }
                                                {product.exchange &&
                                                    <p className="Catalog-product-price Catalog-product-price--exchange">
                                                        Échange
                                                    </p>
                                                }
                                                {(product.stock !== 0 && isInCart(product)) && (
                                                    <Quantity
                                                        product={cart.find(item => item.id === product.id)}
                                                        addProductToCart={addProductToCart}
                                                        removeProductToCart={removeProductToCart}
                                                    />
                                                )}
                                                {(product.stock !== 0 && !isInCart(product)) && (
                                                    <button
                                                        onClick={() => addProductToCart(product)}
                                                        className="Catalog-product-btn"
                                                    >
                                                        Ajouter
                                                    </button>
                                                )}
                                                {product.stock === 0 && (
                                                    <p className="Catalog-product-outOfStock">
                                                        Indisponible
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                    </li>
                                )}

                                {filteredProducts.length < 6 &&
                                    <>{renderEmptyBoxes(filteredProducts.length)}</>
                                }
                            </ul>
                        </div>
                    ) : (
                        <p className="Catalog-empty">
                            Il n'y a actuellement aucun produit disponible dans ce rayon.
                        </p>
                    )
                }
            </section>
        </section>
    );
}

export default Catalog;
