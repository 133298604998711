import { useState } from 'react';
import './App.css';
import Footer from './layout/footer/Footer';
import Header from './layout/header/Header.js';
import Main from './layout/main/Main';

function App() {
    const page = window.location.hash.replace('#', '');
    const initIsVisibleMobileCart = !!(page === 'ma-commande');
    const [isVisibleMobileCart, setIsVisibleMobileCart] = useState(initIsVisibleMobileCart);

    return (
        <div className="App">
            <Header
                isVisibleMobileCart={isVisibleMobileCart}
                setIsVisibleMobileCart={setIsVisibleMobileCart}
            />
            <div className="App-container">
                <Main
                    isVisibleMobileCart={isVisibleMobileCart}
                    setIsVisibleMobileCart={setIsVisibleMobileCart}
                />
                <Footer />
            </div>
        </div>
    );
}

export default App;
