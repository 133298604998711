import classNames from 'classnames';
import { isMoreInStock } from '../../utils';
import './Quantity.css';

function Quantity({
    product,
    addProductToCart,
    removeProductToCart,
}) {
    return (
        <p className="Quantity">
            <span
                onClick={() => removeProductToCart(product)}
                className='Quantity-btn Quantity-btn--less'
            >
                -
            </span>
            <span className='Quantity-value'>
                {product.quantity}
            </span>
            <span
                onClick={() => isMoreInStock(product) ? addProductToCart(product) : null}
                className={classNames(
                    'Quantity-btn',
                    'Quantity-btn--more',
                    {'Quantity-btn--deactivated': !isMoreInStock(product)}
                )}
            >
                +
            </span>
        </p>
    );
}

export default Quantity;
