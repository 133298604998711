import { useState } from 'react';
import './Checkout.css';
import classNames from 'classnames';

function Checkout({
    cart,
}) {
    const [selectedSetting, setSelectedSetting] = useState();

    return (
        <div className="Checkout">
            <div
                onClick={() => {}}
                className={classNames(
                    "Checkout-item",
                    "Checkout-item--onSite",
                    {"Checkout-item--current": selectedSetting === 0}
                )}
            >
                <div className="Checkout-item-header">
                    <h5
                        onClick={() => setSelectedSetting(0)}
                        className={classNames(
                            "Checkout-item-title",
                            "Checkout-item-title--onSite",
                            {"Checkout-item-title--current": selectedSetting === 0},
                            {"Checkout-item-title--inactive": selectedSetting === 1}
                        )}
                    >
                        Retrait et paiement sur place
                        <br />
                        <span className="Checkout-item-subtitle">
                            à Sorges (24420), sur rendez-vous
                        </span>
                    </h5>
                </div>

                {selectedSetting === 0 && <>
                    <p className="Checkout-item-description">
                        Laisse nous tes coordonnées et tes dispos, nous te contacterons dès que possible :
                    </p>
                    <input
                        type="number"
                        placeholder="Ton numéro de téléphone"
                        className="Checkout-Input"
                    />
                    <input
                        type="email"
                        placeholder="Ton adresse e-mail"
                        className="Checkout-Input"
                    />
                    <textarea
                        type="message"
                        placeholder="Message : merci d'indiquer tes disponibilités"
                        className="Checkout-Input"
                    />
                    <button className="Checkout-btn--order Checkout-btn">
                        Envoyer mon bon de commande
                    </button>
                </>}
            </div>
            <div
                onClick={() => {}}
                className={classNames(
                    "Checkout-item",
                    "Checkout-item--LBC",
                    {"Checkout-item--current": selectedSetting === 1}
                )}
            >
                <div className="Checkout-item-header">
                    <h5
                        onClick={() => setSelectedSetting(1)}
                        className={classNames(
                            "Checkout-item-title",
                            "Checkout-item-title--LBC",
                            {"Checkout-item-title--current": selectedSetting === 1},
                            {"Checkout-item-title--inactive": selectedSetting === 0}
                        )}
                    >
                        Paiement en ligne sur Leboncoin.fr
                        <br />
                        <span className="Checkout-item-subtitle">
                            livraison à domicile ou en point relais
                        </span>
                    </h5>
                </div>

                {selectedSetting === 1 && <>
                    <p className="Checkout-item-description">
                        Retrouve ces articles sur <em>Leboncoin.fr</em> et crée un lot pour regrouper les frais de livraison :
                    </p>
                    <ul className="Checkout-item-products">
                        {cart.map(product =>
                            <li className="Checkout-item-product">
                                <a
                                    className="Checkout-item-product-link"
                                    href={product.externalUrl}
                                    title={`Page Leboncoin du produit ${product.label}`}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <span className="Checkout-item-product-title">
                                        {product.label}
                                    </span>
                                    <br />
                                    <span className="Checkout-item-product-url">
                                        {product.externalUrl.replace('http://www', '')}
                                    </span>
                                </a>
                            </li>
                        )}
                    </ul>
                    <a
                        className="Checkout-btn--LBC Checkout-btn"
                        href="http://www.leboncoin.fr/url"
                        title="Page Leboncoin du Truc'shop"
                    >
                        Visiter notre page sur <em>Leboncoin.fr</em>
                    </a>
                </>}
            </div>
        </div>
    );
}

export default Checkout;
