import Cart from '../../components/cart/Cart';
import Presentation from '../../components/presentation/Presentation';
import './Aside.css';

function Aside({
    cart,
    addProductToCart,
    removeProductToCart,
}) {
    return (
        <section className="Aside">
            <Presentation />

            <div className="Aside-cart Aside-block">
                <Cart
                    cart={cart}
                    addProductToCart={addProductToCart}
                    removeProductToCart={removeProductToCart}
                />
            </div>
        </section>
    );
}

export default Aside;
