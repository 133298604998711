import './Presentation.css';

function Presentation() {
    return (
        <div className="Presentation">
            <h3 className="Aside-title">
                Troc & vente de surplus
            </h3>
            <p className="Presentation-item">
                sur place, uniquement sur rendez-vous
                <br /><em>24420 SORGES (Dordogne, France)</em>
            </p>
            <p className="Presentation-item Presentation-item--LBC">
                livraison possible à partir de 40€ d'achat
                <br /><em>paiement en ligne avec leboncoin.fr</em>
            </p>
        </div>
    );
}

export default Presentation;
